import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { Button } from "@ryerson/frontend.button";
import { Media } from "@ryerson/frontend.layout";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";
import { Container } from "@ryerson/frontend.layout";
import rgba from "polished/lib/color/rgba";
import { navigate } from "gatsby";

export interface HeroProps {
	heroTitle: string;
	heroDescription: string[];
	buttonLabel: string;
	hasVideo: boolean;
	heroVideoId: string;
	heroImageUrl?: string;
	thumbnailUrl?: string;
	isWatermarkHero?: boolean;
	watermarkUrl?: string;
	buttonUrl?: string;
}

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 20px;
	margin-bottom: 50px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.white};
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				display: none;
			}
		`;
	}}
`;

const ButtonWrapper = styled.div`
	margin-top: 30px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 0px;
			}
		`;
	}}
`;

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme, buttonOrientation = "right", buttonSize = "md" } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				aspect-ratio: 3/2.5;
				max-width: 100%;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, true, buttonSize)};
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 560px;
				height: 360px;
			}
			@media only screen and (max-width: ${theme.breakpoints.xl}) {
				max-width: ${buttonOrientation && buttonOrientation === "right"
					? "calc(100% - 40px)"
					: "560px"};
			}
		`;
	}}
`;

const ImageWrapper = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				max-width: 560px;
				height: 360px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 300px;
			}
		`;
	}}
`;
const HeroTitle = styled(Typography)`
	margin-top: 32px;
	margin-bottom: 43px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 11px;
				margin-bottom: 13px;
			}
		`;
	}}
`;

const HeroText = styled.div`
	max-width: 358px;
	min-height: 242px;
	margin-bottom: 30px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 0px;
				min-height: 0px;
				max-width: 100%;
				color: ${theme.colors.primary.lightGray};
			}
		`;
	}}
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
    font-${theme.typography.fonts.sizes.xs};
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
        margin-top:8px;
    }
    `;
	}}
`;

const BottomSpacer = styled.div`
	height: 120px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 40px;
			}
		`;
	}}
`;

const Hero: React.FC<HeroProps> = ({
	heroTitle,
	heroDescription,
	buttonLabel,
	hasVideo,
	heroVideoId,
	heroImageUrl,
	thumbnailUrl,
	isWatermarkHero = false,
	watermarkUrl,
	buttonUrl = "/contact-us",
}) => {
	const { theme } = useTheme();

	const imageStyles = css`
		width: 100%;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			height: 100%;
			object-fit: cover;
		}
	`;

	const heroImageVideoContent = (
		hasVideo: boolean,
		heroVideoId: string,
		heroImageUrl?: string,
		thumbnailUrl?: string
	) => {
		if (hasVideo) {
			if (thumbnailUrl) {
				return (
					<>
						<Media greaterThanOrEqual="lg">
							<VideoWrapper theme={theme} buttonOrientation="right">
								<Video
									videoId={heroVideoId}
									imageUrl={thumbnailUrl}
									buttonOrientation="right"
								></Video>
							</VideoWrapper>
						</Media>
						<Media lessThan="lg">
							<VideoWrapper theme={theme} buttonOrientation="top-right">
								<Video
									videoId={heroVideoId}
									buttonOrientation="top-right"
									imageUrl={thumbnailUrl}
								></Video>
							</VideoWrapper>
							<ButtonWrapper theme={theme}>
								<Button
									label={buttonLabel}
									onClick={() => {
										navigate(buttonUrl);
									}}
									fullwidth={true}
								/>
							</ButtonWrapper>
						</Media>
					</>
				);
			} else {
				return (
					<>
						<Media greaterThanOrEqual="lg">
							<VideoWrapper theme={theme} buttonOrientation="right">
								<Video videoId={heroVideoId} buttonOrientation="right"></Video>
							</VideoWrapper>
						</Media>
						<Media lessThan="lg">
							<VideoWrapper theme={theme} buttonOrientation="top-right">
								<Video videoId={heroVideoId} buttonOrientation="top-right"></Video>
							</VideoWrapper>
							<ButtonWrapper theme={theme}>
								<Button
									label={buttonLabel}
									onClick={() => {
										navigate(buttonUrl);
									}}
									fullwidth={true}
								/>
							</ButtonWrapper>
						</Media>
					</>
				);
			}
		} else {
			return (
				<>
					<ImageWrapper theme={theme}>
						<img src={heroImageUrl} css={imageStyles} />
					</ImageWrapper>
					<Media lessThan="lg">
						<ButtonWrapper theme={theme}>
							<Button
								label={buttonLabel}
								onClick={() => {
									navigate(buttonUrl);
								}}
								fullwidth={true}
							/>
						</ButtonWrapper>
					</Media>
				</>
			);
		}
	};

	if (isWatermarkHero) {
		return (
			<Container
				vPadding="0px"
				hPadding="0px"
				css={css`
					background: linear-gradient(
							180deg,
							${rgba(theme.colors.primary.secondaryBrand, 0.6)} 0%,
							${theme.colors.primary.secondaryBrand} 100%
						),
						url(${watermarkUrl});
					background-blend-mode: normal, luminosity;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					max-width: none;
				`}
			>
				<Container>
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<HeroTitle variant="h1" type="tertiary" theme={theme}>
						{heroTitle}
					</HeroTitle>
					<Divider theme={theme} />
					<Grid>
						<Row>
							<Column xs={12} lg={6}>
								<Media greaterThanOrEqual="lg">
									<HeroText theme={theme}>
										{heroDescription.map((description, index) => {
											return (
												<Typography
													variant="p"
													color={theme.colors.primary.white}
													key={index}
													size="md"
												>
													{description}
												</Typography>
											);
										})}
									</HeroText>
									<ButtonWrapper theme={theme}>
										<Button
											label={buttonLabel}
											onClick={() => {
												navigate(buttonUrl);
											}}
											size="lg"
										/>
									</ButtonWrapper>
								</Media>
								<Media lessThan="lg">
									<HeroText theme={theme}>
										{heroDescription.map((description, index) => {
											return (
												<Typography
													variant="p"
													type="tertiary"
													key={index}
													size="md"
												>
													{description}
												</Typography>
											);
										})}
									</HeroText>
								</Media>
							</Column>
							<Column xs={12} lg={6}>
								{heroImageVideoContent(
									hasVideo,
									heroVideoId,
									heroImageUrl,
									thumbnailUrl
								)}
							</Column>
						</Row>
					</Grid>
					<BottomSpacer theme={theme} />
				</Container>
			</Container>
		);
	} else {
		return (
			<ContentSection type="tertiary">
				<BreadcrumbContainer theme={theme}>
					<Breadcrumb type="tertiary" size="xs" />
				</BreadcrumbContainer>
				<HeroTitle variant="h1" type="tertiary" theme={theme}>
					{heroTitle}
				</HeroTitle>
				<Divider theme={theme} />
				<Grid>
					<Row>
						<Column xs={12} lg={6}>
							<Media greaterThanOrEqual="lg">
								<HeroText theme={theme}>
									{heroDescription.map((description, index) => {
										return (
											<Typography
												variant="p"
												color={theme.colors.primary.white}
												key={index}
												size="md"
											>
												{description}
											</Typography>
										);
									})}
								</HeroText>
								<ButtonWrapper theme={theme}>
									<Button
										label={buttonLabel}
										onClick={() => {
											navigate(buttonUrl);
										}}
										size="lg"
									/>
								</ButtonWrapper>
							</Media>
							<Media lessThan="lg">
								<HeroText theme={theme}>
									{heroDescription.map((description, index) => {
										return (
											<Typography
												variant="p"
												type="tertiary"
												key={index}
												size="md"
											>
												{description}
											</Typography>
										);
									})}
								</HeroText>
							</Media>
						</Column>
						<Column xs={12} lg={6}>
							{heroImageVideoContent(
								hasVideo,
								heroVideoId,
								heroImageUrl,
								thumbnailUrl
							)}
						</Column>
					</Row>
				</Grid>
				<BottomSpacer theme={theme} />
			</ContentSection>
		);
	}
};

export default Hero;
